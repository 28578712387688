import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

export const StyledCTA = styled(props => <Link {...props}/>)`
  background: #1b1918;
  transition: all 0.3s ease;
  background: $color1;
  border: none;
  border-radius: 40px;
  box-sizing: border-box;
  color: white;
  display: block;
  margin: 0 auto;
  outline: none;
  overflow: hidden;
  padding: 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 200px;
  z-index: 1;

  span {
    transition: all 0.3s ease;
    font: 700 .7rem 'Assistant';
    letter-spacing: 3px;
    opacity: 1;
    text-transform: uppercase;
  }

  &:hover {
    background: #BE2026;
  }
`;
