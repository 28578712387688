import styled from "styled-components";

export const StyledBanner = styled.div`
  background: ${props => `url(${props.background})`} rgba(22,22,22, 0.5);
  background-attachment: ${({ fixed }) => fixed ? "fixed" : "scroll"};
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
  height: ${props => `${props.height}`};

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
