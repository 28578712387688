import React from "react";
import { StyledBanner } from "./banner.styled";

export default function Banner ({ title, children, background, height, fixed }) {

  return (
    <StyledBanner
      background={background}
      height={height}
      fixed={fixed}
    >
      <div className="content">
        <h1>{title}</h1>
        {children}
      </div>
    </StyledBanner>
  );
}
