import React from "react";
import Layout from "../components/layout/layout";
import CTA from "../components/cta/cta";
import Banner from "../components/banner/banner";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import "../styles/services.scss";
import pageImage from "../images/Audio Mastering.png";

const MasteringPage = () => {

  const data = useStaticQuery(graphql`
    query {
      file (relativePath: {eq: "steve-harvey-702421-unsplash.jpg"}) {
        childImageSharp {
          fluid (maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const backgroundImage = data.file.childImageSharp.fluid.src;

  return (
    <Layout
      title="Online Mastering Services"
      description="Achieve commercial loudness and polish with our mastering services. Contact us to get started!"
      image={pageImage}
    >
      <Banner
        title="Mastering"
        background={backgroundImage}
        height="300px"
      />
      <section className="our-process">
        <div className="container">
          <h3>What is mastering?</h3>
          <p>
            Mastering is the final step of the post-production process. The goal of mastering is to add a final level of “polish” to the mix to optimize playback through various sound systems and media formats.
          </p>
          <h3>How to send your song</h3>
          <p>
            Upload the WAV of your song to a file transfer service such as Dropbox or Google Drive.
          </p>
          <h3>Turnaround time?</h3>
          <p>
            Turnaround time varies depending on the volume of work I receive, but on average 1-2 business days.
          </p>
          <h3>Feedback/Revisions</h3>
          <p>
            Once the master is complete, up to 2 revisions are allowed after the delivery of the first pass.
          </p>
          <h3>What mastering is NOT</h3>
          <p>
            Mastering is not a process that saves bad mixing. Mastering does not offer the level of control that the mixing process does over your song. It is only meant to enhance an already great mix. If you’re listening to your song and it doesn’t sound the way you’d like it to, I recommend requesting mixing services.
          </p>
          <h3>Where can I hear samples of your work?</h3>
          <p>
            You can hear some samples of my most recent projects <Link to="/samples">here</Link>.
          </p>
        </div>
      </section>
      <section className="CTA">
        <div className="container">
          <h2>Ready To Get Started?</h2>
          <CTA text="Request Services" link="/contact"/>
        </div>
      </section>
    </Layout>
  );
};

export default MasteringPage;
