import React from "react";
import { StyledCTA } from "./cta.styled";

const CTA = ({ text, link }) => {

  return (
    <StyledCTA to={link}><span>{text}</span></StyledCTA>
  );
};

export default CTA;
